.faq-section{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: 40px 0px;
    background-color: white;
}
.faq-p{
    width: 90%;
}
.faq-p p{
    width: 80%;
}
.faq-wrapper{
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.faq-container{
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0px;
}
.faq-card{
    width: 100%;
    height: auto;
    transition: .2s ease-in-out all;
    border-radius: 10px;
    padding: 10px 0px;
    background-color:var(--secondary-color);
    margin-top: 30px;
}
.question-tab{
    text-transform: capitalize;
    text-align: left;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.question-tab svg{
    transition: .2s all ease-in-out;
}
.question-tab h2{
    color: var(--primary-color);
    font-weight: 400;
    font-size: 1.1rem;
    font-family: montserrat;
}
.dropdown-btn{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:var(--color-variant);
    color: white;
    cursor: pointer;
    font-size: 1.2rem;
}
.dropdown-btn svg{
    margin: auto;
}
.answer-tab{
    transition: .2s all ease-in-out;
    height: 0px;
    overflow-y: hidden;
    width: 100%;
    text-align: left;
    padding: 0px 20px;
}
.answer-tab p{
    color: #bababa;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    line-height: 25px;
    padding: 30px 0px 10px 0px;
}
.drop{
    transition: all .3s ease-in-out;
    height: 120px;
    min-height: auto;
}
.rotate svg{
    transform: rotate(180deg);
    transition: .2s all ease;
}
@media screen and (max-width: 767px){
    .faq-p p{
        width: 100%;
    }
    .faq-p{
        width: 100%;
    }
    .question-tab h2{
        font-size: .9rem;
        width: 90%;
        line-height: 30px;
    }
    .dropdown-btn{
        font-size: 1rem;
    }
    .answer-tab p{
        font-size: .8rem;
    }
    .drop{
        height: auto;
    }
}