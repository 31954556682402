.roadmap-section{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 50px;
    background-color: white;
}
.roadmap-container{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0px;
    flex-direction: column;
}
.tabs-container{
    width: 40%;
    align-self: flex-start;
}
.tab-content{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 80vh;
}
.tab-img-container{
  width: 50%;
  height: 100%;
  padding: 20px;
}
.tab-img-container img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.tab-text{
  height: 100%;
  width: 50%;
  backdrop-filter: blur(1px);
  padding: 40px 20px;
  text-align: left;
}
.tab-text h2{
  font-family: poppins,monserrat,roboto;
  text-transform: capitalize;
  color: var(--dark);
  font-size: 2rem;
  font-weight: 400;
}

.thin-line{
  display: block;
  width: 100px;
  height: 2px;
  background-color: var(--dark);
  margin-top: 10px;
}
.tab-text p{
  color: var(--dark-mild);
  margin: 20px 0px;
}

.tab-text ul li{
  color: var(--dark-mild);
  transition: all .3s ease-in-out;
  cursor: pointer;
  margin: 13px 0px;
  font-family: lato;
  font-size: .9rem;
  text-transform: capitalize;
  line-height: 22px;
}
.tab-text ul li svg{
  margin-right: 10px;
  color: var(--color-varient);
}
.tab-text ul li:hover{
  margin-left: 5px;
}
.wrapper {
  display: flex;
  list-style: none;
  width: 100%;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.wrapper .icon svg{
  color: var(--color-variant);
  font-weight: bold;
  font-size: 1.5rem;
  margin: auto;
}
.wrapper .icon {
  position: relative;
  background: white;
  border-radius: 50%;
  margin: 10px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  min-width: 100px;
  font-size: 14px;
  background: #fff;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #fff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.wrapper .icon:hover span svg{
  color: white;
}
.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background: var(--color-variant);
  color: #fff;
}
.wrapper .facebook:hover svg{
  color: white;
}
.wrapper .twitter:hover,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip::before {
  background: #1DA1F2;
  color: #fff;
}

.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
  background: #E4405F;
  color: #fff;
}

.wrapper .my-active-tab{
  background-color: var(--color-variant);
  color: white !important;
}
.wrapper .my-active-tab svg{
  color: white;
}
@media (max-width: 990px) and (min-width: 768px){
  .tabs-container{
    width: 100%;
  }
  .tab-text h2{
    font-size: 1.2rem;
  }
  .tab-text p{
    font-size: .9rem;
  }
}
@media screen and (max-width: 767px){
  .tabs-container{
    width: 100%;
  }
  .tab-text h2{
    font-size: 1.2rem;
  }
  .tab-text p{
    font-size: .9rem;
  }
  .wrapper .icon{
    font-size: 14px;
    height: 45px;
    width: 45px;
  }
  .tab-content{
    flex-direction: column-reverse;
    height: auto;
    padding-left: 0px;
  }
  .tab-img-container{
    width: 100%;
    height: auto;
  }
  .tab-text{
    width: 100%;
    margin-left: 0px;
    padding: 20px 0px;
  }
  .roadmap-section{
    height: auto;
  }

}