.service-section{
    width: 100%;
    padding: 30px 0px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.service-card-container{
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.gold-card{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 3px 40px #e5e9f3;
    border-radius: 5px;
}
.gold-card img{
    height: 250px;
    object-fit: cover;
    width: 100%;
    border-radius: 5px;
}
.gold-card-text{
    height: 60%;
    padding:20px;
    text-align: left;
}
.gold-card-text a:hover{
    background-color: rgb(237, 176, 22);
    transition: all .3s ease-in-out;
}
.gold-card-text a{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding: 10px 15px;
    color: white;
    background-color: var(--color-variant);
    border-radius: 10px;
    text-transform: capitalize;
    width: fit-content;
}
.gold-card-text p {
    margin: 10px 0px;
    color: var(--dark-mild);
}
.gold-card-text small{
    color: var(--dark-mild);
}
@media (max-width: 990px) and (min-width: 768px) {
    .service-card-container
    {
        flex-wrap: wrap;
    }
    .gold-card{
        width: 350px;
        margin-top: 30px;
    }
}

@media screen and (max-width: 767px) {
    .gold-card {
            width: 100%;
            margin-top: 50px;
        }
    .service-card-container{
        flex-direction: column;
    }
}