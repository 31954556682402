.feature-section{
    background-color: white;
    width: 100%;
    padding: 30px 0px;
}
.feature-wrapper{
    flex-direction: column;
    align-items: flex-start;
    width: 85%;
    margin: auto;
}
.feature-card-container{
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    flex-wrap: wrap;
    flex-direction: row;
    display: flex;
    margin: auto;
}
.feature-card{
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
    text-align: left;
}
.feature-card h2{
    font-weight: 600;
    color: var(--secondary-color);
    text-transform: uppercase;
    font-size: 1.1rem;
    font-family: montserrat;
}
.feature-card p{
    color: var(--dark-mild);
}
.block-img{
    height: 100px;
    object-fit: contain;
    margin: 10px 0px;
}
.feature-card-text-container svg{
    font-size: 3rem;
    color: var(--color-variant);
}
.feature-card-text-container h2{
    color: var(--dark);
    margin: 15px 0;
}
@media screen and (max-width: 767px){
    .feature-card-container{
        margin-top: 0px;
    }
    .feature-card{
        width: 100%;
        margin: 30px 0px;
    }
}